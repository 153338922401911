<template>
  <Dialog
    v-model:visible="isOpen"
    :closable="false"
    :closeOnEscape="true"
    :style="{ width: '75vw' }"
  >
    <template #header>
      <h3>
        ในปัจจุบันท่านมีอาการปวดในบริเวณใดของร่างกาย
        และมีระดับความปวดเท่าใดจากระดับ 0-10
      </h3>
    </template>
    <DataTable
      :value="selects"
      class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
    >
      <ColumnGroup type="header">
        <Row>
          <Column header="" :rowspan="2" headerStyle="width:13%;" />
          <Column header="ไม่ปวดเลย " :colspan="5" headerClass="p-text-left" />
          <Column
            header="ปวดมากจนทนไม่ได้"
            :colspan="6"
            headerClass="p-text-right"
          />
        </Row>
        <Row>
          <Column header="0" headerClass="p-text-center" />
          <Column header="1" headerClass="p-text-center" />
          <Column header="2" headerClass="p-text-center" />
          <Column header="3" headerClass="p-text-center" />
          <Column header="4" headerClass="p-text-center" />
          <Column header="5" headerClass="p-text-center" />
          <Column header="6" headerClass="p-text-center" />
          <Column header="7" headerClass="p-text-center" />
          <Column header="8" headerClass="p-text-center" />
          <Column header="9" headerClass="p-text-center" />
          <Column header="10" headerClass="p-text-center" />
        </Row>
      </ColumnGroup>

      <Column header="Name" field="topic" bodyStyle="white-space: nowrap; " />

      <!-- option 0-->
      <Column header="ไม่ปวดเลย(0)" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="0"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 0-->

      <!-- option 1-->
      <Column header="1" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="1"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 1-->

      <!-- option 2-->
      <Column header="2" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="2"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 2-->

      <!-- option 3-->
      <Column header="3" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="3"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 3-->

      <!-- option  4-->
      <Column header="4" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="4"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 4-->

      <!-- option 5-->
      <Column header="5" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="5"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 5-->

      <!-- option 6-->
      <Column header="6" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="6"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 6-->

      <!-- option 7-->
      <Column header="7" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="7"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 7-->

      <!-- option 8-->
      <Column header="8" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="8"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 8-->

      <!-- option 9-->
      <Column header="9" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="9"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 9-->

      <!-- option 10-->
      <Column header="ปวดมากจนทนไม่ได้(10)" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="10"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 10-->
    </DataTable>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => setModal(false)"
      />
      <Button
        label="ยืนยันผล"
        icon="pi pi-check"
        autofocus
        @click="setLabelValueAndCloseModal"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalPainScore',
  data() {
    return {
      selects: [
        { name: 'painNeck', topic: '1. คอ', select: null },
        { name: 'painShoulder', topic: '2. ไหล่', select: null },
        { name: 'painScapula', topic: '3. สะบัก', select: null },
        { name: 'painElbow', topic: '4. ข้อศอก/แขน', select: null },
        { name: 'painHand', topic: '5. ข้อมือ/มือ/นิ้ว', select: null },
        { name: 'painBack', topic: '6. หลังส่วนเอว', select: null },
        { name: 'painHip', topic: '7. สะโพก/ต้นขา', select: null },
        { name: 'painKnee', topic: '8. ข้อเข่า/น่อง', select: null },
        { name: 'painFoot', topic: '9. ข้อเท้า/เท้า/นิ้ว', select: null }
      ]
    }
  },
  computed: {
    isOpen() {
      return this.$store.getters['prediction/isModalPainScore']
    }
  },
  methods: {
    setModal(isOpen) {
      this.$store.commit('prediction/setModal', {
        modalName: 'pain_score',
        isOpen
      })
    },
    setLabelValueAndCloseModal() {
      const value = this.selects.reduce((sum, row) => {
        console.log('row ::== ' + JSON.stringify(row))
        const { select } = row
        return sum + (select == null ? 0 : select)
      }, 0)
      console.log('value ::==', value)
      this.$store.commit('prediction/setLabelValue', {
        labelName: 'pain_score',
        value
      })
      this.setModal(false)
    }
  }
}
</script>

<style>
</style>